import { parseDateTime } from '@/utils/ruoyi'
import moment from 'moment'

function gpsTime() {
  const d = new Date()
  if (timeRange('7:00', '10:30') || timeRange('16:00', '19:00')) {
  } else if (timeRange('10:30', '16:00')) {
    d.setHours('10')
    d.setMinutes('30')
    d.setSeconds('00')
  } else {
    d.setHours('19')
    d.setMinutes('0')
    d.setSeconds('00')
  }
  const time = parseDateTime(d, 'yyyy-MM-dd HH:mm:ss')
  return time
}

function timeRange(beginTime, endTime) {
  var strb = beginTime.split(':')

  if (strb.length !== 2) {
    return false
  }

  var stre = endTime.split(':')
  if (stre.length !== 2) {
    return false
  }

  var b = new Date()
  var e = new Date()
  var n = new Date()

  b.setHours(strb[0])
  b.setMinutes(strb[1])
  e.setHours(stre[0])
  e.setMinutes(stre[1])

  if (n.getTime() - b.getTime() >= 0 && n.getTime() - e.getTime() < 0) {
    return true
  } else {
    return false
  }
}

export const getVehicleLocationsData = () => {
  return [
    {
      imei: '01',
      deviceName: '01小神童',
      icon: 'automobile',
      status: '1',
      posType: 'WIFI',
      lat: 41.180814,
      lng: 80.295693,
      hbTime: '2021-10-22 15:53:17',
      accStatus: '0',
      gpsTime: gpsTime(), // 5分钟前定位
      activationFlag: '1',
      expireFlag: '1',
      electQuantity: '100',
      name: '谢军平',
      jobNumber: 'R-AKS-000004',
      licensePlateNum: '新111212'
    },
    {
      imei: '02',
      deviceName: '02小神童',
      icon: 'automobile',
      status: '1',
      posType: 'WIFI',
      lat: 41.185019,
      lng: 80.290599,
      hbTime: '2021-10-22 15:51:58',
      accStatus: '0',
      gpsTime: moment()
        .subtract(15, 'M')
        .format('YYYY-MM-DD HH:mm:ss'), // 5分钟前定位
      activationFlag: '1',
      expireFlag: '1',
      electQuantity: '100',
      mileage: '28612.084967974606',
      name: '闫晓英',
      jobNumber: 'R-AKS-000007',
      licensePlateNum: '新N26597'
    },
    {
      imei: '03',
      deviceName: '03小神童',
      icon: 'automobile',
      status: '1',
      posType: 'WIFI',
      lat: 41.171069,
      lng: 80.272112,
      hbTime: '2021-10-22 15:49:00',
      accStatus: '0',
      gpsTime: parseDateTime(moment().subtract(7, 'minutes'), 'yyyy-MM-dd HH:mm:ss'), // 5分钟前定位
      activationFlag: '1',
      expireFlag: '1',
      electQuantity: '46',
      name: '谢军平',
      jobNumber: 'R-AKS-000004',
      licensePlateNum: '新212112'
    },
    {
      imei: '04',
      deviceName: '04小神童',
      icon: 'automobile',
      status: '0',
      posType: 'WIFI',
      lat: 41.158339,
      lng: 80.279031,
      hbTime: '2021-10-22 11:43:09',
      accStatus: '0',
      gpsTime: parseDateTime(moment().subtract(8, 'minutes'), 'yyyy-MM-dd HH:mm:ss'), // 5分钟前定位
      activationFlag: '1',
      expireFlag: '1',
      electQuantity: '0',
      name: '布亥力且木·玉山',
      jobNumber: 'R-AKS-000018',
      licensePlateNum: '新N26579'
    },
    {
      imei: '05',
      deviceName: '05小神童',
      icon: 'per',
      status: '1',
      posType: 'WIFI',
      lat: 41.149882,
      lng: 80.284052,
      hbTime: '2021-10-22 15:49:31',
      accStatus: '0',
      gpsTime: parseDateTime(moment().subtract(18, 'minutes'), 'yyyy-MM-dd HH:mm:ss'), // 5分钟前定位
      activationFlag: '1',
      expireFlag: '1',
      electQuantity: '98',
      mileage: '38754.47024829426',
      name: '蒋大志',
      jobNumber: 'R-AKS-000001',
      licensePlateNum: '新N42696'
    },
    {
      imei: '06',
      deviceName: '06小神童',
      icon: 'automobile',
      status: '0',
      posType: 'WIFI',
      lat: 41.18391,
      lng: 80.261505,
      hbTime: '2021-10-22 13:03:21',
      accStatus: '0',
      gpsTime: parseDateTime(moment().subtract(28, 'minutes'), 'yyyy-MM-dd HH:mm:ss'), // 5分钟前定位
      activationFlag: '1',
      expireFlag: '1',
      electQuantity: '0',
      name: '胡西塔尔·麦木塔吾拉',
      jobNumber: 'R-AKS-000010',
      licensePlateNum: '新221222'
    }
  ]
}
